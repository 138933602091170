/* Hide Calendar Icon In Chrome */
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.checkout {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  gap: 1rem;
  min-height: 100vh;
  padding-bottom: 10rem;
  background-color: var(--light-black);
  color: var(--white);
  font-family: var(--poppins-font);
  background-image: none;
}
.checkout h2 {
  margin: 0 auto;
  font-size: 3rem;
  margin-top: 10px;
  text-transform: uppercase;
  margin-top: 40px;
}

.checkout-form-container {
  display: grid;
  grid-template-columns: 1fr; /* Default to one column */
  gap: 2rem;
  padding: 2rem;
}
@media (min-width: 768px) {
  .checkout-form-container {
    grid-template-columns: 1fr 1fr; /* Two columns on screens wider than 768px */
  }
}

@media (min-width: 1024px) {
  .checkout-form-container {
    grid-template-columns: repeat(
      3,
      1fr
    ); /* Three columns on screens wider than 1024px */
  }
}

.checkout-item {
  display: grid;
  grid-template-columns: 5rem 1fr;
  gap: 1rem;
  width: max-content;
  height: 4rem;
  margin-bottom: 5rem;
}
.checkout-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.checkout-item-info {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 15rem;
}
.checkout-item-info h3 {
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--yellow);
  word-wrap: break-word;
}
/* PERSONAL INFORMATION */
.checkout-personal-information form {
  border: 2px solid #ccc; /* Add border */
  border-radius: 8px; /* Add border radius for rounded corners */
  padding: 20px; /* Add padding inside the form */
  width: 80vw; /* Define maximum width */
  margin: 0 auto; /* Center the form horizontally */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items horizontally */
  justify-content: center; /* Center items vertically */
  box-shadow: 0 4px 6px rgb(216, 195, 11); /* Add box shadow for 3D effect */
}

@media (min-width: 768px) {
  .checkout-personal-information form {
    width: 40vw; /* Two columns on screens wider than 768px */
  }
  .checkout-personal-information form input {
    width: 5rem;
  }
}
.checkout-personal-information form h3 {
  font-size: 2rem;
}

/* Style form inputs and labels */
label {
  display: block; /* Display labels as block elements */
  color: #2c3e50;
}

.checkout-personal-information form input,
.checkout-personal-information form textarea {
  width: 100%; /* Make inputs and textarea fill the width of the form */
  padding: 10px; /* Add padding to inputs */
  margin-bottom: 20px; /* Add space between inputs */
  border: 1px solid #ccc; /* Add border to inputs */
  border-radius: 4px; /* Add border radius for rounded corners */
  box-sizing: border-box; /* Ensure padding and border are included in width */
}
.delivery-options,
.location-type {
  display: grid;
  margin-bottom: 20px;
  grid-template-columns: 1fr 1fr;
  margin-top: 1rem;
}
.delivery-options label,
.location-type label {
  margin: 0 10px; /* Adjust margin between labels */
  margin-bottom: 2rem;
}

/* Style button */
.checkout-personal-information form button {
  width: 100%; /* Make button fill the width of the form */
  padding: 10px; /* Add padding to button */
  color: #fff; /* Set button text color */
  border: none; /* Remove button border */
  border-radius: 4px; /* Add border radius for rounded corners */
  cursor: pointer; /* Add pointer cursor on hover */
}

/* Add responsiveness */
@media (max-width: 768px) {
  .checkout-personal-information form {
    max-width: 90%; /* Adjust maximum width for smaller screens */
  }
}

.active-button-style {
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.active-button-style:hover {
  background-color: rgb(40, 39, 39);
}

.checkout-address,
.checkout-number {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
}

.checkout-personal-information h3 a,
.checkout-address a,
.checkout-number a {
  font-size: 1rem;
  color: rgb(103, 15, 186);
}

.checkout-personal-information,
.checkout-personal-information form,
.checkout-choose-delivery,
.checkout-choose-delivery form,
.checkout-choose-payment,
.delivery,
.promo-code {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.promo-code input {
  color: var(--whitish-gray);
  font-family: var(--poppins-font);
  font-weight: 400;
  font-size: 1rem;
  border: 1px solid var(--light-gray);
  padding: 1rem;
  background-color: transparent;
  font-weight: 400;
  font-size: 1rem;
  width: 20%;
}

/* CHOOSE DELIVERY */
.checkout-choose-delivery form label {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  width: 50%;
}
.takeaway[type="radio" i],
.delivery[type="radio" i] {
  -moz-appearance: none;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  border: 1px solid var(--white);
  padding: 0.5rem;
  border-radius: 50%;
}
.takeaway[type="radio" i]:checked,
.delivery[type="radio" i]:checked {
  border: 1px solid var(--white);
  background-color: var(--pizza-red);
}
.takeaway:checked,
.delivery:checked {
  width: 1rem;
}

.takeaway-option,
.delivery-option {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}
.takeaway-option svg {
  width: 1rem;
  height: 1rem;
}
/* PROMOCODE */
.promo-code p {
  cursor: pointer;
  color: rgb(103, 15, 186);
  font-family: var(--poppins-font);
  font-weight: 400;
  font-size: 1rem;
  transition: all ease-in-out 0.2s;
  text-decoration: underline;
}
.promo-code p:hover {
  color: white;
  transition: all ease-in-out 0.2s;
}

input[type="password"] {
  color: var(--whitish-gray);
  font-family: var(--poppins-font);
  font-weight: 400;
  font-size: 1rem;
}

/* CART TOTALS */

.checkout-carttotals {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  max-width: 1200px;
}

.checkout-carttotals .cart-carttotals .totals-content {
  display: flex;
  flex-direction: row;
  font-family: var(--poppins-font);
  color: var(--whitish-gray);
  text-transform: uppercase;
  padding: 1rem;
  font-size: 1.2rem;
  gap: 1rem;
}
.cart-totals {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.checkout-carttotals .totals-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 300px;
}
.checkout-carttotals .totals-content h4 {
  color: #2c3e50;
}
.checkout-carttotals .totals-content p {
  font-weight: 600;
  color: var(--yellow);
}

.checkout-choose-payment input[type="date"] {
  color: var(--whitish-gray);
  font-family: var(--poppins-font);
  font-weight: 400;
  font-size: 1rem;
  text-transform: uppercase;
}

/* BUTTONS */
.checkout-interaction-btns {
  display: flex;
  flex-direction: column;
  max-width: 65%;
  width: 100%;
  align-items: flex-start;
  gap: 1rem;
}

.checkout-choose-payment label {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 700px) {
  .checkout-choose-payment label {
    flex-direction: row;
    justify-content: space-between;
  }
}

/* Modal Container */
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

/* Modal Overlay */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Modal Content */
.modal-content {
  text-align: center;
}

/* Modal Heading */
.modal-content h2 {
  margin-bottom: 10px;
  color: #333;
}

/* Modal Text */
.modal-content p {
  color: #666;
  margin-bottom: 20px;
}

/* Close Button */
.modal-content button {
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* Close Button Hover */
.modal-content button:hover {
  background-color: #0056b3;
}

/*custom font*/
@import url(https://fonts.googleapis.com/css?family=Montserrat);

/*form styles*/
#msform {
  text-align: center;
  position: relative;
  margin: 0 auto;
  width: 80vw; /* Default to full width */
}

/* Media query for screens wider than 768px */
@media (min-width: 769px) {
  #msform {
    max-width: 80vw; /* 80% of the viewport width */
  }
}

#msform fieldset {
  background: white;
  border: 0 none;
  border-radius: 0px;
  box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.4);
  padding: 20px 30px;
  box-sizing: border-box;
  width: 80vw;
  margin: 0;
  position: relative;
  display: none;
}

/*Hide all except first fieldset*/
#msform fieldset:first-of-type {
  display: block;
}

/*inputs*/
#msform input,
#msform textarea {
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 0px;
  margin-bottom: 10px;
  width: 100%;
  box-sizing: border-box;
  font-family: montserrat;
  color: #2c3e50;
  font-size: 13px;
}

#msform input:focus,
#msform textarea:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 1px solid var(--yellow);
  outline-width: 0;
  transition: All 0.5s ease-in;
  -webkit-transition: All 0.5s ease-in;
  -moz-transition: All 0.5s ease-in;
  -o-transition: All 0.5s ease-in;
}

/*buttons*/
#msform .action-button {
  width: fit-content;
  background: var(--yellow);
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 25px;
  cursor: pointer;
  padding: 10px 2rem;
  margin: 10px 5px;
}

#msform .action-button:hover,
#msform .action-button:focus {
  box-shadow: 0 0 0 2px white, 0 0 0 3px var(--yellow);;
}

#msform .action-button-previous {
  width: 100px;
  background: #c5c5f1;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 25px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px;
}

#msform .action-button-previous:hover,
#msform .action-button-previous:focus {
  box-shadow: 0 0 0 2px white, 0 0 0 3px #c5c5f1;
}

/*headings*/
.fs-title {
  font-size: 18px;
  text-transform: uppercase;
  color: #2c3e50;
  margin-bottom: 10px;
  letter-spacing: 2px;
  font-weight: bold;
}

.fs-subtitles {
  font-size: 32px;
  margin-top: 1.5rem;
  text-transform: uppercase;
  color: #2c3e50;
  margin-bottom: 10px;
  letter-spacing: 2px;
  font-weight: bold;
}

.fs-subtitle {
  font-weight: normal;
  font-size: 13px;
  color: #666;
  margin-bottom: 20px;
}

/*progressbar*/
#progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  /*CSS counters to number the steps*/
  counter-reset: step;
}

#progressbar li {
  list-style-type: none;
  color: white;
  text-transform: uppercase;
  font-size: 9px;
  width: 50%;
  float: left;
  position: relative;
  letter-spacing: 1px;
}

#progressbar li:before {
  content: counter(step);
  counter-increment: step;
  width: 24px;
  height: 24px;
  line-height: 26px;
  display: block;
  font-size: 12px;
  color: #333;
  background: white;
  border-radius: 25px;
  margin: 0 auto 10px auto;
}

/*progressbar connectors*/
#progressbar li:after {
  content: "";
  width: 100%;
  height: 2px;
  background: white;
  position: absolute;
  left: -50%;
  top: 9px;
  z-index: -1; /*put it behind the numbers*/
}

#progressbar li:first-child:after {
  /*connector not needed before the first step*/
  content: none;
}

/*marking active/completed steps green*/
/*The number of the step and the connector before it = green*/
#progressbar li.active:before,
#progressbar li.active:after {
  background: var(--yellow);
  color: white;
}

/* Not relevant to this form */
.dme_link {
  margin-top: 30px;
  text-align: center;
}
.dme_link a {
  background: #fff;
  font-weight: bold;
  color: #ee0979;
  border: 0 none;
  border-radius: 25px;
  cursor: pointer;
  padding: 5px 25px;
  font-size: 12px;
}

.dme_link a:hover,
.dme_link a:focus {
  background: #c5c5f1;
  text-decoration: none;
}
