@media screen and (min-width: 550px) {
  .meal-item {
    flex-direction: row;
    padding: 1rem;
  }
  .meal-item img {
    max-width: 50%;
  }
}

@media screen and (min-width: 700px) {
  .pricing-grid {
    gap: 2.5rem;
  }
  .gallery,
  .highlights,
  .pricing-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  footer,
  .dish-categories {
    flex-direction: row !important;
  }
  .blog-posts-landing {
    flex-direction: row;
  }

  .blog-grid {
    padding-top: 3rem;
  }
  .section-10 {
    justify-content: space-evenly;
    gap: 5rem;
  }

  footer {
    justify-content: space-evenly;
  }
  .section-2-info {
    width: 65%;
    margin: 0 auto;
  }
}
@media screen and (min-width: 800px) {
  .meals-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 2rem;
    width: 800px;
  }

  .meal-item {
    gap: 1rem;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: flex-start;
  }
  .meal-item img {
    max-width: 100%;
    height: 100%;
  }
  .meal-item-details {
    display: grid;
    grid-template-rows: 1fr;
  }
  .hero-section {
    height: 30rem;
  }
  .pizza-one {
    right: -22%;
    top: -20%;
    width: 20rem;
  }
  .pizza-two {
    left: -22%;
    top: -20%;
    width: 20rem;
  }
}
@media screen and (min-width: 1025px) {
  .navigation-menu {
    display: flex;
  }
  .section-10 {
    flex-direction: row !important;
  }
  .map {
    height: auto;
    width: 80%;
  }
  .meals-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 2rem;
    width: 970px;
  }
  .burger-bars {
    display: none;
  }
  .main-nav {
    width: 100%;
    z-index: 999;
  }

  .header-info p {
    width: 70%;
    margin: 0 auto;
  }

  .services-grid {
    display: flex;
    flex-direction: row;
  }

  .company-details {
    align-items: center;
  }

  .gallery {
    grid-template-columns: repeat(4, 1fr);
  }
  .highlights {
    grid-template-columns: repeat(4, 1fr);
  }

  .section-8 {
    flex-direction: row;
    justify-content: space-evenly;
    max-width: 1440px;
    margin: 0 auto;
  }
  .menu-slider-hero {
    max-width: 40%;
    object-fit: cover;
  }

  .section-11 {
    display: flex;
    flex-direction: row;
  }
  .section-11.heading {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 2rem;
  }
  .hero-section {
    height: 40rem;
  }
  .pizza-one {
    display: block;
    right: -40%;
    top: -20%;
    width: 23rem;
  }
  .pizza-two {
    display: block;
    left: -40%;
    top: -20%;
    width: 23rem;
  }
}

@media screen and (min-width: 1440px) {
  .pizza-one {
    right: -15%;
    top: 3%;
  }
  .pizza-two {
    left: -15%;
    top: 3%;
  }
  .meals-grid {
    gap: 1rem;
    overflow-y: hidden;
    width: 1200px;
  }
}
